const infuraKey = '254b5e2346274031a656970595058edf';
const Web3Modal = window.Web3Modal.default;
const web3Modal = new Web3Modal({
	cacheProvider: true,
	theme: 'dark',
	providerOptions: {
		walletconnect: {
			package: WalletConnectProvider,
			options: {
				infuraId: infuraKey,
			},
		},
		// walletlink: {
		// 	package: WalletLink,
		// 	options: {
		// 		appName: 'fees.wtf',
		// 		infuraId: infuraKey,
		// 		darkMode: true,
		// 	},
		// },
		// mewconnect: {
		// 	package: MewConnect,
		// 	options: {
		// 		infuraId: infuraKey,
		// 	},
		// },
	},
});

let provider = undefined;
let accountWeb3 = undefined;
let currentAccount = undefined;
const accountsChanged = (accounts) => {
	if (accounts.length) {
		currentAccount = accounts[0].toLowerCase();
	} else {
		currentAccount = undefined;
	}
};

const connectWallet = async () => {
	if (accountWeb3 === undefined) {
		try {
			provider = await web3Modal.connect();
			accountWeb3 = new ethers.providers.Web3Provider(provider);

			const accounts = await accountWeb3.listAccounts();
			if (accounts.length) {
				currentAccount = accounts[0].toLowerCase();
			} else {
				currentAccount = undefined;
			}

			provider.on('accountsChanged', accountsChanged);
		} catch {}
	}
};
if (web3Modal.cachedProvider) {
	connectWallet();
}

const connectAccount = (account) => {
	if (/^0x[0-9a-f]{40}$/.test(account.toLowerCase())) {
		localStorage.setItem('account', account.toLowerCase());
	}
};

const disconnectWallet = async () => {
	if (provider && typeof provider.off === 'function') {
		provider.off('accountsChanged', accountsChanged);
	}
	if (
		accountWeb3 &&
		accountWeb3.currentProvider &&
		accountWeb3.currentProvider.close
	) {
		await accountWeb3.currentProvider.close();
	}
	await web3Modal.clearCachedProvider();
	localStorage.removeItem('account');
	provider = undefined;
	accountWeb3 = undefined;
	currentAccount = undefined;
};

const windowWeb3 = window.web3;
const windowEthereum = window.ethereum;
const web3 = windowEthereum
	? new ethers.providers.Web3Provider(windowEthereum)
	: windowWeb3
	? new ethers.providers.Web3Provider(windowWeb3.currentProvider)
	: new ethers.providers.CloudflareProvider();
const web3cf = new ethers.providers.CloudflareProvider();

const windowAccount = () => {
	let account = undefined;
	const localAccount = localStorage.getItem('account');
	if (localAccount !== null && /^0x[0-9a-f]{40}$/.test(localAccount)) {
		account = localAccount;
	} else if (accountWeb3) {
		account = currentAccount;
	}
	return account;
};

const abi = [
	{
		inputs: [
			{
				internalType: 'address',
				name: '_user',
				type: 'address',
			},
		],
		name: 'balanceOf',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256',
			},
		],
		stateMutability: 'view',
		type: 'function',
	},
];
const WTFNFT = new ethers.Contract(
	'0x9D90308D16b94C38048C782BDD8104a964758A23',
	abi,
	web3cf
);
const checkPro = async (user) => parseInt(await WTFNFT.balanceOf(user)) > 0;

const formatAddress = async (account) => {
	const name = await db.names.where({ address: account.toLowerCase() }).first();
	if (name && name.name) {
		return name.name;
	} else {
		return `${account.slice(0, 7)}…${account.slice(-5)}`;
	}
};

const apiUrl = 'https://pro-api.fees.wtf';

const currencies = {
	usd: '$',
	eur: '€',
	gbp: '£',
	aud: '$',
	cny: '¥',
	jpy: '¥',
	xag: '',
	xau: '',
};
let currentCurrency = 'usd';
let updateCurrency;

let prices;
let proRequired = false;
let skipsSync = false;
let syncsNames = false;
let syncsSigs = false;
let syncing = false;
const startSync = async () => {
	if (!syncing) {
		syncing = true;
		$('#connect-account').hide();
		$('#syncing').show();
		$('#pro-required').hide();
		$('#main').hide();
		if (proRequired && !(await checkPro(lastAccount))) {
			$('#syncing').hide();
			$('#pro-required').show();
			$('#main').hide();
		} else {
			if (!skipsSync) {
				await sync(lastAccount, currentChain);
				if (syncsNames) {
					await syncNames(lastAccount, currentChain);
				}
				if (syncsSigs) {
					await syncSigs();
				}
			}
			if (lastAccount !== undefined || skipsSync) {
				if (prices === undefined) {
					const list = chains.map((e) => sources[e].gecko);
					const priceInfo = await (
						await fetch(
							`https://api.coingecko.com/api/v3/simple/price?${new URLSearchParams(
								{
									ids: list.filter((e, i) => list.indexOf(e) === i),
									vs_currencies: Object.keys(currencies).join(','),
								}
							).toString()}`
						)
					).json();
					prices = {};
					chains.forEach((e) => (prices[e] = priceInfo[sources[e].gecko]));
				}
				try {
					updateCurrency();
					await render();
				} catch (err) {
					console.log(err);
				}
				$('#syncing').hide();
				$('#pro-required').hide();
				$('#main').show();
			}
		}
		syncing = false;
	}
};

let lastAccount = null;
const checkAccount = async () => {
	const account = windowAccount();
	if (account !== lastAccount) {
		lastAccount = account;
		if (account !== undefined) {
			$('#connected').show();
			$('#not-connected').hide();
			$('#connect-account').hide();
			$('#current-address').text(await formatAddress(account));
			const holder = $('#blockies');
			holder.empty();
			holder.append(blockies.create({ seed: account.toLowerCase() }));
			startSync();
			const pro = await checkPro(account);
			$('.menu-pro')
				.removeClass('pro')
				.addClass(pro ? 'pro' : '');
		} else {
			$('#connected').hide();
			$('#not-connected').show();
			$('#connect-account').show();
			$('#syncing').hide();
			$('#pro-required').hide();
			$('#main').hide();
			if (skipsSync) {
				startSync();
			}
		}
	}
};

const log10 = (val) => {
	return Math.log(val) / Math.log(10);
};

const formatNumber = (num, maxDecimals, allowFormat = false) => {
	if (num === undefined || num === '' || Number.isNaN(num)) {
		return '-';
	} else {
		let d = parseInt(maxDecimals);
		let n = parseFloat(num);
		let zeros = Math.floor(log10(Math.abs(n)));
		if (allowFormat && n > 0 && n < Math.pow(10, -1 * Math.max(0, d - 2))) {
			d = Math.min(3 - zeros, d + 4);
		}
		let postfix = '';
		if (zeros >= 9) {
			postfix = 'B';
			n /= 1e9;
			zeros -= 9;
		} else if (zeros >= 6) {
			postfix = 'M';
			n /= 1e6;
			zeros -= 6;
		}

		zeros = Math.min(d, d - zeros);

		return (
			n.toLocaleString('en-US', {
				minimumFractionDigits: 0,
				maximumFractionDigits: Math.max(zeros, 0),
			}) + postfix
		);
	}
};

$(document).ready(() => {
	$('#selected-network').text(sources[currentChain].name);
	$(window).on('hashchange', () => {
		setTimeout(() => {
			$('#selected-network').text(sources[currentChain].name);
			startSync();
		}, 100);
	});

	updateCurrency = () => {
		const tmpCurrency = localStorage.getItem('currency');
		if (tmpCurrency !== null && currencies[tmpCurrency] !== undefined) {
			currentCurrency = tmpCurrency;
		}
		$('#currency').text(
			`${currencies[currentCurrency]} ${currentCurrency.toUpperCase()}`
		);
		$('.chain-prefix').text(sources[currentChain].prefix);
		$('.chain-suffix').text(sources[currentChain].suffix);
		$('.currency-name').text(currentCurrency.toUpperCase());
		$('.currency-symbol').text(currencies[currentCurrency]);
	};
	updateCurrency();
	let currencyHtml = '';
	const keys = Object.keys(currencies);
	for (let i = 0; i < keys.length; i += 2) {
		currencyHtml += `<div class="row mx-0">${keys
			.slice(i, i + 2)
			.map(
				(e) =>
					`<div class="col-6 px-0 text-center"><a href="javascript:void(0);" class="dropdown-item px-2" currency="${e}">${
						currencies[e]
					} ${e.toUpperCase()}</a></div>`
			)
			.join('')}</div>`;
	}
	$('#currency-options').html(currencyHtml);
	$('#currency-options a').click(async (e) => {
		localStorage.setItem('currency', $(e.currentTarget).attr('currency'));
		try {
			updateCurrency();
			await render();
		} catch (err) {
			console.log(err);
		}
	});

	$('.connect-wallet').click(connectWallet);
	$('#connect-account-btn').click(() => connectAccount($('#account').val()));
	$('#disconnect').click(disconnectWallet);
	if (skipsSync) {
		$('#syncing-text').text('Loading...');
	}
	checkAccount();
	setInterval(checkAccount, 100);
});

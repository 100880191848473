const read = async (
	address,
	chain,
	minTimestamp = Dexie.minKey,
	maxTimestamp = Dexie.maxKey
) => {
	address = address.toLowerCase();
	const info = sources[chain];
	if (info && /^0x[0-9a-f]{40}$/.test(address)) {
		const data = await db.txs
			.where('[address+chain+timestamp]')
			.between([address, chain, minTimestamp], [address, chain, maxTimestamp])
			.reverse()
			.sortBy('block');
		data.forEach((e) => {
			e.cost = (e.gasPrice * e.gasUsed) / 1e9;
			delete e.address;
			delete e.chain;
		});
		return data;
	} else {
		return [];
	}
};

const signatures = async () => {
	return (await db.sigs.toArray()).map((e) => ({
		sig: e.sig,
		func: e.func,
		name: e.func.split('(')[0],
	}));
};

const names = async () => {
	return (await db.names.toArray()).map((e) => ({
		address: e.address,
		name: e.name,
	}));
};

const reduce = (data) => ({
	totalFees: data.reduce((a, v) => a + v.cost, 0),
	failFees: data.filter((e) => e.failed).reduce((a, v) => a + v.cost, 0),
	totalTxs: data.length,
	failTxs: data.filter((e) => e.failed).length,
	totalGas: data.reduce((a, v) => a + v.gasUsed, 0),
	avgGwei: data.reduce((a, v) => a + v.gasPrice / data.length, 0),
	avgFee: data.reduce((a, v) => a + v.cost / data.length, 0),
	minFee: data.reduce(
		(a, v) => (a === null ? v.cost : Math.min(a, v.cost)),
		null
	),
	maxFee: data.reduce(
		(a, v) => (a === null ? v.cost : Math.max(a, v.cost)),
		null
	),
	lastTx: data.length ? data[0].timestamp : null,
});

const stats = async (
	address,
	chain,
	minTimestamp = Dexie.minKey,
	maxTimestamp = Dexie.maxKey
) => {
	return reduce(await read(address, chain, minTimestamp, maxTimestamp));
};

const allStats = async (address) => {
	const info = {};
	await Promise.all(
		chains.map(async (chain) => {
			const data = await stats(address, chain);
			if (data.totalTxs > 0) {
				info[chain] = data;
			}
		})
	);
	return info;
};

const aggregatedStats = async (
	address,
	chain,
	start,
	period,
	count,
	reverse
) => {
	const info = [];
	await Promise.all(
		Array.from(
			{ length: count },
			(_, i) => start + (reverse ? -1 : 1) * period * i
		).map(async (time) => {
			const data = await stats(
				address,
				chain,
				reverse ? time - period : time,
				reverse ? time : time + period
			);
			info.push({
				time,
				...data,
			});
		})
	);
	info.sort((a, b) => a.time - b.time);
	return info;
};

const interactions = async (
	address,
	chain,
	minTimestamp = Dexie.minKey,
	maxTimestamp = Dexie.maxKey
) => {
	const data = await read(address, chain, minTimestamp, maxTimestamp);
	const info = {
		all: reduce(data),
		deploys: reduce(data.filter((e) => e.sig === 'deploy')),
	};
	data
		.filter((e) => e.sig !== 'deploy')
		.forEach((e) => {
			if (!info[e.to]) {
				info[e.to] = { all: [] };
			}
			if (!info[e.to][e.sig]) {
				info[e.to][e.sig] = [];
			}
			info[e.to].all.push(e);
			info[e.to][e.sig].push(e);
		});
	const sigs = await signatures();
	Object.keys(info)
		.filter((e) => e.startsWith('0x'))
		.forEach((to) =>
			Object.keys(info[to]).forEach((sig) => {
				const reduced = reduce(info[to][sig]);
				const match = sigs.map((e) => e.sig).indexOf(sig);
				if (match === -1) {
					info[to][sig] = reduced;
				} else {
					if (
						sigs[match].name !== 'all' &&
						info[to][sigs[match].name] === undefined
					) {
						delete info[to][sig];
						info[to][sigs[match].name] = reduced;
					} else {
						info[to][sig] = reduced;
					}
				}
			})
		);
	return info;
};

const allInteractions = async (address) => {
	const info = {};
	await Promise.all(
		chains.map(async (chain) => {
			const data = await interactions(address, chain);
			if (data.all.totalTxs > 0) {
				info[chain] = data;
			}
		})
	);
	return info;
};

const aggregatedInteractions = async (
	address,
	chain,
	start,
	period,
	count,
	reverse
) => {
	const info = [];
	await Promise.all(
		Array.from(
			{ length: count },
			(_, i) => start + (reverse ? -1 : 1) * period * i
		).map(async (time) => {
			const data = await interactions(
				address,
				chain,
				reverse ? time - period : time,
				reverse ? time : time + period
			);
			info.push({
				time,
				interactions: data,
			});
		})
	);
	info.sort((a, b) => a.time - b.time);
	return info;
};

const db = new Dexie('feeswtfDB');
db.version(1).stores({
	txs: '&[address+chain+block+index],[address+chain+timestamp],sig',
	sigs: '&sig',
});
db.version(2).stores({
	txs: '&[address+chain+block+index],[address+chain+timestamp],[address+chain+to],sig,to',
	sigs: '&sig,func',
	names: '&address,name',
});

const sources = {
	ETH: {
		name: 'Ethereum',
		explorer: 'https://etherscan.io',
		base: 'https://api.etherscan.io/api',
		key: 'T9RV3FGW573WX9YX45F1Z89MEMEUNQXUC7',
		prefix: 'Ξ',
		suffix: 'ETH',
		gecko: 'ethereum',
	},
	FTM: {
		name: 'Fantom',
		explorer: 'https://ftmscan.com',
		base: 'https://api.ftmscan.com/api',
		key: '3688BXQEMBZUV7IKC19ESJ7IGQNIAPQBIM',
		prefix: '',
		suffix: 'FTM',
		gecko: 'fantom',
	},
	MATIC: {
		name: 'Polygon',
		explorer: 'https://polygonscan.com',
		base: 'https://api.polygonscan.com/api',
		key: '5GKG9DZ2CXTJ9ZHQ1MRM8V64WCHXV5G2EZ',
		prefix: '',
		suffix: 'MATIC',
		gecko: 'matic-network',
	},
	OPTIMISTIC: {
		name: 'Optimistic',
		explorer: 'https://optimistic.etherscan.io',
		base: 'https://api-optimistic.etherscan.io/api',
		key: 'HSHKHHQFJYSZ7SQ9E5AS87WCAYUJI9GDGI',
		prefix: 'Ξ',
		suffix: 'ETH',
		gecko: 'ethereum',
	},
	BSC: {
		name: 'BSC',
		explorer: 'https://bscscan.com',
		base: 'https://api.bscscan.com/api',
		key: 'TYX364WCB8EIHA89KGTHBM92W823V4N9V7',
		prefix: '',
		suffix: 'BNB',
		gecko: 'binancecoin',
	},
	CRO: {
		name: 'Crono',
		explorer: 'https://cronoscan.com',
		base: 'https://api.cronoscan.com/api',
		key: 'XXT9XTVW1QQXW4HNYQQE8T34GQBFUMT6XY',
		prefix: '',
		suffix: 'CRO',
		gecko: 'crypto-com-chain',
	},
	HT: {
		name: 'Heco',
		explorer: 'https://hecoinfo.com',
		base: 'https://api.hecoinfo.com/api',
		key: 'A77MTQNZJNTDJSBPG5J3179WV6EHGZS3G8',
		prefix: '',
		suffix: 'HT',
		gecko: 'ethereum', // doesn't exist
	},
	HOO: {
		name: 'HOO',
		explorer: 'https://hooscan.com',
		base: 'https://api.hooscan.com/api',
		key: '5DXBRFEIGI3MJRJ7GKJB62R3XHWKRMSDS8',
		prefix: '',
		suffix: 'HOO',
		gecko: 'hoo-token',
	},
	ARBITRUM: {
		name: 'Arbitrum',
		explorer: 'https://arbiscan.io',
		base: 'https://api.arbiscan.io/api',
		key: 'CC5D8THVEQBANV7I5QS2RFCJS5AIZZ4KVX',
		prefix: 'Ξ',
		suffix: 'ETH',
		gecko: 'ethereum',
	},
	AVAX: {
		name: 'Avalanche',
		explorer: 'https://snowtrace.io',
		base: 'https://api.snowtrace.io/api',
		key: '2DQFP4DBK9SHGV6PNTDTDFM2NVYBPNNMCV',
		prefix: '',
		suffix: 'AVAX',
		gecko: 'avalanche-2',
	},
};
const chains = Object.keys(sources);
let currentChain;
const hashChange = () => {
	const chain = window.location.hash;
	if (chain && chains.indexOf(chain.slice(1)) !== -1) {
		currentChain = chain.slice(1);
	} else {
		currentChain = 'ETH';
	}
};
hashChange();
$(window).on('hashchange', hashChange);

const sync = async (address, chain) => {
	address = address.toLowerCase();
	const info = sources[chain];
	if (info && /^0x[0-9a-f]{40}$/.test(address)) {
		const last = await db.txs
			.where('[address+chain+timestamp]')
			.between([address, chain, Dexie.minKey], [address, chain, Dexie.maxKey])
			.last();
		let from = last ? last.block : 0;
		let page = 1;
		const pageSize = 2000;
		let results;
		try {
			while (!results || results.length === pageSize) {
				const json = await (
					await fetch(
						`${info.base}?module=account&action=txlist&address=${address}&startblock=${from}&endblock=9999999999&page=${page}&offset=${pageSize}&sort=asc&apikey=${info.key}`
					)
				).json();
				if (json.status === '1') {
					results = json.result;
					const data = results
						.filter((e) => e.from.toLowerCase() === address)
						.map((e) => ({
							address,
							chain,
							block: parseInt(e.blockNumber),
							index: parseInt(e.transactionIndex),
							timestamp: parseInt(e.timeStamp),
							hash: e.hash,
							to: e.to.length
								? e.to.toLowerCase()
								: e.contractAddress.toLowerCase(),
							sig: e.to.length ? e.input.slice(0, 10) : 'deploy',
							gasUsed: parseInt(e.gasUsed),
							gasPrice: parseFloat(
								ethers.utils.formatUnits(
									ethers.BigNumber.from(e.gasPrice),
									'gwei'
								)
							),
							failed: e.isError === '1' || e.txreceipt_status === '0',
						}));
					if (data.length > 0) {
						try {
							await db.txs.bulkAdd(data);
						} catch {}
					}
					page++;
					if (page * pageSize > 10000) {
						from = parseInt(results[results.length - 1].blockNumber);
						page = 1;
					}
				} else {
					break;
				}
			}
		} catch {}
	}
};

const syncAll = async (address) => {
	await Promise.all(
		chains.map(async (chain) => {
			await sync(address, chain);
		})
	);
};

const syncSigs = async () => {
	const allSigs = await db.txs.orderBy('sig').uniqueKeys();
	const knownSigs = await db.sigs.orderBy('sig').uniqueKeys();
	const sigs = allSigs.filter(
		(e) => /^0x[0-9a-f]{8}$/.test(e) && knownSigs.indexOf(e) === -1
	);
	try {
		for (let i = 0; i < sigs.length; i += 100) {
			const info = await (
				await fetch(`${apiUrl}/sigs`, {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify(sigs.slice(i, i + 100)),
				})
			).json();
			const data = Object.keys(info).map((e) => ({
				sig: e,
				func: info[e],
			}));
			if (data.length > 0) {
				try {
					await db.sigs.bulkAdd(data);
				} catch {}
			}
		}
	} catch {}
};

const syncNames = async (address, chain, extras = []) => {
	const allAddresses = (await db.txs.orderBy('[address+chain+to]').uniqueKeys())
		.filter((e) => e[0] === address && e[1] === chain)
		.map((e) => e[2]);
	const knownNames = await db.names.orderBy('address').uniqueKeys();
	const addresses = [
		...allAddresses.filter(
			(e) => /^0x[0-9a-f]{40}$/.test(e) && knownNames.indexOf(e) === -1
		),
		...extras.filter(
			(e) =>
				/^0x[0-9a-f]{40}$/.test(e) &&
				allAddresses.indexOf(e) === -1 &&
				knownNames.indexOf(e) === -1
		),
	];
	try {
		for (let i = 0; i < addresses.length; i += 100) {
			const info = await (
				await fetch(`${apiUrl}/names`, {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({
						chain,
						addresses: addresses.slice(i, i + 100),
					}),
				})
			).json();
			const data = Object.keys(info).map((e) => ({
				address: e,
				name: info[e],
			}));
			if (data.length > 0) {
				try {
					await db.names.bulkAdd(data);
				} catch {}
			}
		}
	} catch {}
};

const clearDB = async () => {
	await db.txs.clear();
	await db.sigs.clear();
	await db.names.clear();
};
